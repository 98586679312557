import React from 'react'
import styled, { css } from 'styled-components'
import { Helmet } from 'react-helmet'

import Layout from '../components/Layout'

import PageNotFoundIcon from '../assets/icons/page-not-found.svg'
import { Link } from 'gatsby';

const Box = styled.div`
  margin: 0 auto;
  padding: 3em 1.5em 2em;
  text-align: center;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Text = styled.p`
  text-align: center;
  line-height: 1.6;
  margin: 0 0 1em 0;
`

const NotFoundPage = ({ location }) => (
  <div>
    <Layout location={location}>
      <Helmet>
        <title>404 - Page Not Found</title>
        <meta name="description" content="Page not found" />
      </Helmet>

      <Box>
        <PageNotFoundIcon css={css`height: 300px;`} />
        <Text>Sorry, that page can't be found</Text>
        <Link to="/">Go back to home</Link>
      </Box>
    </Layout>
  </div>
)

export default NotFoundPage
